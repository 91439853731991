<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container-xl">
                <div class="navbar-brand">實聯快疫通<small class="ml-3">Speed Pass</small></div>
            </div>
        </nav>

        <div class="container pb-5">
            <div class="row pt-5">

                <div class="col-12 order-md-1">
                    <h4 class="mb-3">{{locationName}} - 實聯制登記名單<br>{{reportDate}}</h4>
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">姓名</th>
                            <th scope="col">電話</th>
                            <th scope="col">同行人數</th>
                            <th scope="col">登記時間</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in reportList" :key="index">
                            <th scope="row">{{index+1}}</th>
                            <td>{{item.USER_NAME}}</td>
                            <td>{{item.PHONE_NUMBER}}</td>
                            <td>{{item.ATTENDANCE}}</td>
                            <td>{{item.LOG_TIME}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <small>依據「個人資料保護法之特定目的及個人資料之類別」代號012公共衛生或傳染病防治之特定目的，蒐集以上個人資料，且不得為目的外利用。所蒐集之資料僅保存 28 日，屆期銷毀。
                    </small>
                </div>
            </div>
            <div class="login_power">power by <span><img src="/images/img_logo_w.png"></span></div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import axios from "axios";

    export default {
        name: "StoreDiaryList",
        created() {
            const reportSid = this.$route.params.reportSid
            const reportParams = _.split(reportSid, '|')
            this.reportDate = atob(reportParams[0])
            this.locationSid = reportParams[1]
            this.initialLoad(reportSid)
        },
        data: function() {
            return {
                locationName: '',
                locationSid: '',
                reportDate: '',
                reportList: []
            }
        },
        methods: {
            initialLoad: function (reportSid) {
                axios.post('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/querydailyreport',
                    {reportSid: reportSid})
                    .then((res) => {
                        const result = JSON.parse(res.data.body)
                        if (result.MESSAGE === 'SUCCESS') {
                            this.reportList = result.REPORT_LIST
                            if (this.reportList.length > 0) {
                                this.locationName = this.reportList[0].LOCATION_NAME
                                this.reportDate = this.reportList[0].REPORT_DATE
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>
